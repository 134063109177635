//
// Environment
//


//
// Config values
//

$platform-name: 'aspenscharities';


//
// Colours
//

$_navy: #29265B;
$_pink: #CE007B;
$_purple: #662482;
$_green: #C6CE40;
$_cyan: #23B4E2;
$_plum: #8A1461;

$brand-primary: $_navy;
$brand-secondary: $_cyan;

$donate-colour: $_pink;

// Create greyscale
$black:             #000 !default;
$grey-dark:         #292b2c !default;
$grey:              #464a4c !default;
$grey-light:        #636c72 !default;
$grey-lighter:      #eceeef !default;
$grey-lightest:     #f7f7f9 !default;
$white:             #fff !default;


//
// Layout
//

$container-max-width: 1230px;
$post-content-width: 980px;


// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px
) !default;


// Spacers
$spacer: 1rem !default;


//
// Misc
//

$date-ordinals-enabled: false; // Turn off 'st', 'nd', 'rd' from dates


//
// Transitions
//



//
// Logo
//

$logo-width: 220px;
$logo-height: 75px;


//
// Typography
//

$font-family-base: sofia-pro-soft, sans-serif;
$font-size-base: 1.125rem;

$font-weight-normal: normal !default;
$font-weight-bold: bold !default;

$font-size-h1: 2.5em !default;
$font-size-h2: 2em !default;
$font-size-h3: 1.75em !default;
$font-size-h4: 1.5em !default;
$font-size-h5: 1.25em !default;
$font-size-h6: 1em !default;

$headings-margin-top: 1.5em !default; // This will only affect headings within post content


// Links
$link-colour: $brand-secondary;

// Icon font


//
// Buttons
//

$btn-padding-x: 1.5em !default;
$btn-padding-y: .6em !default;
$btn-font-weight: $font-weight-bold;
$btn-background-colour: $brand-secondary;

// Donate button

//
// Social icons
// Documentation: https://fresco-docs.netlify.com/#/header/social-icons/
//

// Social network colours - use these by changing $social-icons-use-brand-colours to true
$social-icons-background-colour: transparent; // A colour, transparent, or 'brand'
$social-icons-colour: $brand-primary; // A colour, or 'brand'
$social-icons-hover-colour: brand; // A colour, or 'brand'
$social-icons-footer-colour: $brand-secondary; // A colour, or 'brand'

// Header specific

// Footer specific social icons


//
// Share this page
//



//
// Form elements
//

$input-padding-y: .375rem !default;
$input-padding-x: .75rem !default;


// Field groups


//
// Tables
//



//
// Card
//

$card-border: 0;
$card-footer-background-colour: transparent;

// Card text overlay

// Card text over

// Card side by side

// Card hover state
$card-hover-image-opacity: .7;
$card-hover-image-scale: 1.15;


//
// Menu admin
//
$menu-admin-absolute: true;
$menu-admin-background-colour: transparent;
$menu-admin-link-colour: $brand-primary;
$menu-admin-login-link-enabled: true;
$menu-admin-to-nav-breakpoint: 40000px;
//
// Header
//


// Header content
$header-content-margin-top: 0;
$header-content-padding-top: $spacer ;

// Tagline

// Main call to action

// Search

// Search - input
$header-search-input-margin-right: -43px;
$header-search-input-padding: $input-padding-y 43px $input-padding-y $input-padding-x;

// Search - button
$header-search-button-background-colour: transparent;
$header-search-button-icon-colour: $grey;

// Social icons

// Colours set above (search for '$social-icons-header')


//
// Sticky header
//


//
// Navigation
//
$nav-background-colour: $grey-lighter;


// Top level items
$nav-top-level-item-font-weight: $font-weight-bold;
$nav-top-level-item-hover-colour: white;
$nav-top-level-item-hover-background-colour: $brand-primary;
$nav-top-level-chevrons-enabled: true;

// Submenus
$nav-submenu-background-colour: $brand-primary;
$nav-submenu-box-shadow: 0 20px 20px rgba(black, 0.05);
$nav-submenu-item-colour: rgba(white,0.7);
$nav-submenu-item-hover-colour: white;
$nav-submenu-item-hover-background-colour: rgba($black, 0.3);

// Burger button

// Nav normal
$nav-normal-max-width: 100%; // $container-max-width
$nav-normal-margin-bottom: 0;
$nav-normal-align-items: left; // left, center, right


//
// Specifically when no banner present and you have gone for $header-absolute: true
//



//
// Carousel
//
$carousel-max-width: 100%;
$carousel-details-position-y: center; // top, center, bottom
$carousel-contents-max-width: $container-max-width; // How wide do you want .carouselDetailWrapper?
$carousel-details-background-colour: transparent;
$carousel-heading-colour: white;
$carousel-summary-colour: white;
$carousel-details-margin-y: $spacer * 3;
$carousel-heading-font-size: $font-size-h1;
$carousel-details-max-width: 500px;
$carousel-image-overlay: linear-gradient(69.67deg, rgba(0, 0, 0, 0.6) 23.21%, rgba(0, 0, 0, 0.4) 46.48%, rgba(0, 0, 0, 0) 64.28%); // Add a colour value or gradient here
$carousel-summary-font-size: 1.125em;
$carousel-read-more-font-size: 1rem;
$carousel-details-padding: 0;


// Carousel controls (left-right buttons)
$carousel-controls-position-y: center; // top, center, bottom
$carousel-controls-position-x: split; // split, left or right

// Carousel buttons (dots or tabs)
$carousel-buttons-enabled: false;

// Carousel buttons dots

// Carousel buttons tabs

// Carousel video play button (before breakpoint, when carousel details present)

// Carousel scroll button

// Carousel after breakpoint

// Banner min-height under sm breakpoint - helps to keep some vertical height at narrow viewports, especially for letter-box aspect ratios

//
// Home intro
//
$home-intro-margin-top: 0;
$home-intro-background-image: 'triangles.svg';
$home-intro-background-image-opacity: 1;
$home-intro-text-align: left;
$home-intro-background-colour: white;



//
// Home features
//
$home-features-max-width: 100%;
$home-features-padding-top: 0;
$home-features-padding-bottom: 0;
$home-features-cards-entry-animation-prefab: 1; // See https://fresco-docs.netlify.com/#/cards/entry-animations



//
// Impact stats
//
$impact-stats-background-colour: transparent;
$impact-stats-colour: $brand-secondary;
// $impact-stats-background-image: 'triangles.svg';


// Heading
$impact-stats-heading-colour: $grey-dark;

// Individual stat

// Figure

// Summary


//
// Home feeds
//
$home-feeds-cards-entry-animation-prefab: 1; // See https://fresco-docs.netlify.com/#/cards/entry-animations


// Feeds title

// Feed item

// Event feed items
$feed-item-event-location-enabled: false;

// Twitter feed


//
// Footer
//
$footer-boxes-count: 3;
$footer-prefab: 7;
$footer-background-colour: $_navy;
$footer-colour: white;
$footer-link-colour: $brand-secondary;
$footer-padding-y: $spacer * 5;

// Footer admin links

// Newsletter
$newsletter-background-colour: transparent;
$newsletter-use-placeholders: true; // Turn on to use placeholders in the inputs rather than labels
$newsletter-heading-colour: white;
$newsletter-heading-text-align: left; // left, center, right
$newsletter-heading-font-size: $font-size-h4;
$newsletter-colour: #fff;

// Newsletter hero - full width form above rest of footer


//
// Context container
//


// Donors list


//
// Posts
//

$post-content-max-width: $post-content-width;


//
// Header text (container for breadcrumb and page title)
//

$header-text-margin-top: $spacer;


//
// Page title
//

$page-title-margin-top: $spacer * 3;


//
// Sidebar
//

$sidebar-enabled: false; // Sidebars will always exist on event and appeal posts

// Associated lists

//
// Listing
//


// Listed post settings
$listed-appeal-totaliser-enabled: true;

//
// Breadcrumb
//



//
// Blockquote
//



//
// Fundraising
//


// Find a fundraiser widget

// Top fundraisers


//
// Donation form
//
$donation-form-banner-enabled: false;

// Donation amounts


//
// Quick giving panel
//



//
// Home quick giving panel
//
$home-quick-giving-padding-y: $spacer * 13;
$home-quick-giving-background-image: 'donate.jpg';
$home-quick-giving-background-colour: $grey-dark;
$home-quick-giving-heading-colour: white;


// Adjacent homepage quick giving

// Overlay homepage quick giving


//
// Totalisers
$totaliser-enabled: true;
$totaliser-layout: horizontal; // vertical or horizontal
$totaliser-width: 60px;
$totaliser-panel-background-colour: transparent;



//
// Comments
//



//
// Blog details
//



//
// FAQs
//



//
// Shop
//

$basket-link-only-on-shop-pages: true;

// Departments list

// Product-price

// Product post


//
// Subsite
//


// Subsite logo

// Subsite nav


//
// Cookie consent
//
