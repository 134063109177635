@mixin card-text-over-image (
  $card-text-over-image-vertical-text-position: $card-text-over-image-vertical-text-position,
  $card-text-over-image-darken-image-amount: $card-text-over-image-darken-image-amount,
  $card-text-over-image-tint-colour: $card-text-over-image-tint-colour,
  $card-text-over-image-show-summary-on-hover: $card-text-over-image-show-summary-on-hover,
  ) {
  position: relative;

  #{$card-details} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    background-color: rgba($card-text-over-image-tint-colour, $card-text-over-image-darken-image-amount);

    h2, h3, .feedTitle, > p {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      padding: 1rem; // This is a little hard coded
      display: flex;
      flex-direction: column;

      @if $card-text-over-image-vertical-text-position == center {
        justify-content: center;
      }

      @if $card-text-over-image-vertical-text-position == bottom {
        justify-content: flex-end;
      }
    }

    > p {
      opacity: 0;
      pointer-events: none;
    }
  }

  @if $card-text-over-image-show-summary-on-hover == true {
    &:hover {
      #{$card-details} {
        h2, h3, .feedTitle {
          opacity: 0;
          pointer-events: none;
        }

        > p {
          opacity: 1;
          pointer-events: all;
        }
      }
    }
  }

  footer {
    display: none; // This is never going to work
  }
}
