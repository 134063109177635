// Useful mixin for before and after dividers
@mixin beforeafter {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    overflow: hidden;
}

// Add divider to carousel top/bottom
body:not(.page-no-banner) .carouselSlides {
    position: relative;
    &:after {
        @include beforeafter;
        height: calc((78 / 1600) * 100vw);
        bottom: -1px;
        background-image: url($assets-path + "carousel-curve.svg");
        background-size: 102%;
    }
}

// Add divider to footer
.pageFooterWrapper {
    position: relative;
    &:after {
        @include beforeafter;
        height: calc((78 / 1600) * 100vw);
        top: -1px;
        background-image: url($assets-path + "carousel-curve.svg");
        background-size: 102%;
        transform: rotate(180deg);
    }
}
.pageFooter {
    padding-bottom: $spacer * 3;
}

// Add white line to top and right of feature boxes
.homeFeatures {
    border-top: 0.5px solid white;
    border-right: 0.5px solid white;
}

// Change behaviour of text-over-image
.homeFeatures .homeFeature [class*="DetailsWrapper"]:not(.publishDetailsWrapper) {
    justify-content: center;
    > h2, > p {
        position: relative;
        opacity: 1;
    }
    > h2 {
        padding-bottom: 0;
    }
    &:hover {
        > h2, > p {
            opacity: 1;
        }
    }
}

// Add lines to top level
.menuMain .topLevel .level1 {
    border-right: 0.5px solid white;
    &:first-child {
        border-left: 0.5px solid white;
    }
}

// home intro
.homeIntroWrapper:before {
    background-position: right;
    transform: scaleY(-1);
}

// Footer stuff
.footerBox.footerBox1 {
    font-size: 1rem;
}
.footerBox.footerBox2 {
    .socialIcons {
        margin-left: -0.75rem;
        margin-top: 1rem;
    }
}

// Mob fixes
@media (max-width: map-get($breakpoints, lg)) {
    .homeIntro {
        padding-right: $spacer * 12;
    }
    .carouselSlideDetail {
        padding-bottom: $spacer * 3;
    }
}

// Shop customisation
@media (min-width: 1025px) {
    .listedProduct {
        width: calc( (100% - 90px) / 3 - 1px);
    }
}
.shopLandingPage {
    .breadcrumbWrapper {
        max-width: $container-max-width;
    }
}

.menuMain .header-search button:before {
  color: #fff;
}

// Appeal body
.appealPostPage .post .contentBlock {
  display: block;
}
